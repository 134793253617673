import type { ICart } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

export default defineNuxtRouteMiddleware((to) => {
  if (to.query.cupom) {
    const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)

    cart.value = { ...cart.value, coupon: to.query.cupom as string }

    useDialogCouponApplied().value = true
  }
})
