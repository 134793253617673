import { defineAsyncComponent } from 'vue'
export default {
  "checkout-new-journey": defineAsyncComponent(() => import("/builds/trevo/web/layouts/checkout-new-journey.vue").then(m => m.default || m)),
  checkout: defineAsyncComponent(() => import("/builds/trevo/web/layouts/checkout.vue").then(m => m.default || m)),
  "checkout-clean": defineAsyncComponent(() => import("/builds/trevo/web/layouts/checkoutClean.vue").then(m => m.default || m)),
  "checkout-with-resume-fixed": defineAsyncComponent(() => import("/builds/trevo/web/layouts/checkoutWithResumeFixed.vue").then(m => m.default || m)),
  clean: defineAsyncComponent(() => import("/builds/trevo/web/layouts/clean.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/builds/trevo/web/layouts/default.vue").then(m => m.default || m)),
  "new-journey": defineAsyncComponent(() => import("/builds/trevo/web/layouts/new-journey.vue").then(m => m.default || m)),
  "schedule-confirmation": defineAsyncComponent(() => import("/builds/trevo/web/layouts/scheduleConfirmation.vue").then(m => m.default || m))
}