import isValidDate from '@/utils/validation/isValidDate'

export default (date: Date): any => {
  if (!date)
    return ''

  const dateFormatter = new Date(date)

  if (!isValidDate(dateFormatter))
    return date

  return dateFormatter.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}
