<script setup lang="ts">
const props = defineProps({
  dark: {
    type: Boolean,
    default: false,
  },

  disableLink: {
    Boolean,
    default: false,
  },

})
const content = useContent()

const logoSrc = computed<string>(() => props.dark ? content.value.logoDarkUrl : content.value.logoWhiteUrl)
const logoAlt = computed<string>(() => content.value.sellerName)
</script>

<template>
  <div v-if="logoSrc">
    <Img
      v-if="disableLink"
      :src="logoSrc"
      :alt="logoAlt"
      class="min-w-[134px]"
      width="134"
      height="42"
      data-testid="logo"
      :preload="true"
      priority="high"
    />

    <NuxtLink
      v-else
      no-prefetch
      data-testid="logo-link"
      to="/"
    >
      <Img
        :src="logoSrc"
        :alt="logoAlt"
        class="min-w-[134px]"
        width="134"
        height="42"
        :preload="true"
        priority="high"
      />
    </NuxtLink>
  </div>
</template>
