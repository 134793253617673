<script setup lang="ts">
import type { IAccessTokenDecoded } from '@/types/types'
import { EventName, LocalStorageKey, PhoneVerificationCodeError, PhoneVerificationCodeErrorMessage } from '@/types/enums'
import { Form, FormField } from '@primevue/forms'
import { zodResolver } from '@primevue/forms/resolvers/zod'
import { jwtDecode } from 'jwt-decode'
import InputOtp from 'primevue/inputotp'
import { z } from 'zod'

const props = defineProps({
  phone: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['close', 'verified', 'retry', 'open-whatsapp-modal'])

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')
const refreshToken = useLocalStorage(LocalStorageKey.RefreshToken, '')

const formToken = ref()

const retry = ref(false)
const content = useContent()

const whatsAppModal = ref(false)

const counter = ref(59)
const counterInterval = ref()

const verificationToken = ref('')

const resolver = zodResolver(
  z.object({
    verificationToken: z.string().min(6, { message: 'Código de autenticação incorreto' }),
  }),
)

function retryOnVerifyCodeSubmit() {
  emit('retry')
  retry.value = true
  verificationToken.value = ''
}

const isLoading = ref(false)

async function onVerifyCodeSubmit() {
  const payload = {
    phone: formatPhone(props.phone),
    phoneVerificationCode: verificationToken.value,
  }

  try {
    isLoading.value = true

    let response = await $fetch('/v1/customer/users/verify_phone_code', { method: 'POST', baseURL: useRuntimeConfig().public.api.awsGateway, body: camelToSnake(payload) })

    response = snakeToCamel(response)

    const decodedToken: IAccessTokenDecoded = snakeToCamel(jwtDecode(response.accessToken))

    accessToken.value = response.accessToken
    refreshToken.value = response.refreshToken

    track(EventName.TokenVerified, {
      phone: formatPhone(props.phone),
      isNewUser: !decodedToken.name,
    })

    if (temporaryRoute().value) {
      navigateTo(temporaryRoute().value)
      temporaryRoute().value = undefined
    }
  }
  catch (error: any) {
    const errorCode = error?.data?.error_code

    let message = {
      message: 'Ocorreu um erro inesperado, tente novamente.',
    }

    if (Object.values(PhoneVerificationCodeError).includes(errorCode)) {
      message = PhoneVerificationCodeErrorMessage[errorCode as PhoneVerificationCodeError]

      formToken.value.fields.token.states.error = { message: message.message }
      formToken.value.fields.token.states.invalid = true
    }
    else {
      errorTracker(error)
    }
  }
  finally {
    isLoading.value = false
  }
}

function cleanCounter() {
  clearInterval(counterInterval.value)
  counterInterval.value = null
}

function startCounter() {
  counterInterval.value = setInterval(() => {
    if (counter.value === 0)
      cleanCounter()
    else
      counter.value--
  }, 1000)
}

onNuxtReady(startCounter)
</script>

<template>
  <section class="px-8 py-6 text-sm lg:min-w-[600px]">
    <div class="flex items-center justify-between">
      <h3 class="text-primary-400 text-xl">
        Informações de entrada
      </h3>

      <Icon
        name="mdi:close"
        class="text-primary-400 size-4 cursor-pointer"
        @click="$emit('close')"
      />
    </div>

    <div class="flex flex-col mt-4">
      <h2 class="text-neutral-300 font-semibold font-sora mb-1">
        Qual é o seu código?
      </h2>
      <p class="text-neutral-300">
        <span class="block lg:inline"> Digite o código enviado para </span>
        <span class="text-neutral-300 whitespace-nowrap">{{ props.phone }}.</span>
      </p>
    </div>

    <Form ref="formToken" :resolver class="flex flex-col gap-4 items-center ">
      <div class="flex flex-col gap-4 items-center mt-4">
        <div class="flex justify-center">
          <span class="text-neutral-300 text-xs font-semibold">
            Código de verificação
          </span>
        </div>

        <FormField v-slot="$field" name="token" class="flex flex-col items-center w-full">
          <label
            for="token"
            class="flex flex-col items-center font-bold"
          >
            <InputOtp
              v-model="verificationToken"
              data-testid="token"
              :disabled="isLoading"
              :length="6"
            />

            <Message
              v-if="$field?.invalid"
              severity="error"
              size="small"
              class="text-xs mt-2 text-error-500"
              variant="simple"
            >
              {{ $field.error?.message }}
            </Message>
          </label>
        </FormField>
      </div>

      <p v-if="counter" class="text-neutral-300 text-xs font-semibold">
        Reenviar código em
        <span class="font-bold underline">
          00:{{ counter > 10 ? counter : `0${counter}` }}
        </span>
      </p>

      <span
        v-else-if="counter === 0 && !retry"
        class="text-neutral-200 text-xs font-semibold underline cursor-pointer"
        @click="retryOnVerifyCodeSubmit"
      >
        Reenviar código
      </span>

      <p v-else-if="retry" class="text-neutral-200 text-xs font-semibold">
        Problemas para
        <span
          class="text-information-500 font-semibold cursor-pointer"
          @click="whatsAppModal = true"
        >
          acessar a conta?
        </span>
      </p>

      <p class="bg-bg-50 p-4 text-neutral-100 rounded-lg text-center max-w-[400px]">
        Ao clicar em Confirmar, concordo com os
        <NuxtLink no-prefetch class="cursor-pointer font-bold" to="#">
          Termos de Serviço
        </NuxtLink> e
        <NuxtLink no-prefetch class="cursor-pointer font-bold" to="#">
          Política de Privacidade
        </NuxtLink>
        d{{ content.labArticle }} {{ content.sellerName }}.
      </p>

      <Button
        data-testid="verify-token-button"
        class="w-full md:w-[296px]"
        :disabled="verificationToken.length < 6"
        :loading="isLoading"
        @click.prevent="onVerifyCodeSubmit"
      >
        Confirmar
      </Button>
    </form>

    <div class="px-10">
      <ModalWhatsApp
        v-if="whatsAppModal"
        text="Problemas para acessar <br /> sua conta"
        @close="whatsAppModal = false"
      />
    </div>
  </section>
</template>
