<template>
  <teleport to="body">
    <div
      class="flex justify-center items-center h-full w-full fixed top-0 bg-white z-50"
    >
      <div class="inline-block animate-pulse">
        <Logo
          disable-link
        />
      </div>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.pulse-image {
  animation: pulse 2s infinite;
}
</style>
