<script lang="ts" setup>
defineEmits(['close'])

const content = useContent()
</script>

<template>
  <Dialog @close="$emit('close')">
    <div class="flex flex-col items-center w-full xl:w-[805px] xl:max-w-[805px] overflow-y-auto">
      <div class="bg-gradient-to-b from-1% from-secondary-200 to-80% to-base-50 w-full rounded-t-lg relative">
        <div class="bg-[url('https://web-cdn.saudetrevo.com.br/illustrations/banner-tickets.svg')] w-full">
          <div class="flex flex-col items-center justify-center py-6">
            <h2 class="text-2xl text-primary-400 font-bold">
              Cupons {{ content.sellerName }}
            </h2>
            <h4 class="text-primary-400 text-base font-semibold">
              Mais benefícios e desconto para sua saude
            </h4>
          </div>

          <Icon
            name="mdi:close"
            class="text-primary-400 absolute right-[30px] xl:right-[94px] top-11 cursor-pointer"
            @click="$emit('close')"
          />
        </div>
      </div>

      <CartPromoForm class="mt-4" />
    </div>
  </Dialog>
</template>
