import type { LocalStorageKey } from '@/types/enums'
import * as Sentry from '@sentry/vue'

export default (key: LocalStorageKey): string | null => {
  try {
    const identifier = localStorage.getItem(key)

    if (!identifier)
      return null

    return JSON.parse(identifier)[key] || null
  }
  catch (error) {
    Sentry.captureException(error)

    return null
  }
}
