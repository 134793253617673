<script setup lang="ts">
interface IOption {
  text: string
  value: string
}
const props = defineProps({
  name: {
    type: String,
    default: 'select-name',
  },
  label: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<IOption[]>,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
})

const selection = defineModel(
  {
    type: String,
    default: '',
  },
)
const show = ref(false)

const showOptions = () => (show.value = true)

const hideOptions = () => (show.value = false)
</script>

<template>
  <div class="flex flex-col relative">
    <select
      :id="id"
      v-model="selection"
      class="peer h-14 py-2.5 px-3 text-neutral-100 border bg-transparent rounded-lg duration-200 text-sm font-bold outline-0 transition-all placeholder-shown:border focus:border-2 border-neutral-100 focus:border-primary-400 focus:border-t-transparent focus:outline-0 disabled:border-neutral-50"
      :class="{
        'border-t-transparent': selection,
        'border-error-500 text-error-500': error,
      }"
      :name="props.name"
      :disabled="disabled"
      @change="handleSelect"
      @focusin="showOptions"
      @focusout="hideOptions"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        class="text-bg-100"
      >
        {{ option.text }}
      </option>
    </select>

    <span
      class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full text-neutral-100 w-full select-none text-sm transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-neutral-50 before:transition-all after:border-neutral-50 after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r peer-focus:border-primary-400 after:transition-all peer-placeholder-shown:text-sm peer-focus:text-xs peer-focus:mt-0 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:after:border-t-2 peer-focus:after:border-r-2 duration-100 before:duration-100 after:duration-100"
      :class="{
        'mt-0 before:border-neutral-100 after:border-neutral-100': selection,
        'mt-6 before:border-transparent after:border-transparent':
          !selection,
        'peer-focus:after:border-error-500 peer-focus:text-error-500 peer-focus:before:border-error-500 peer-placeholder-shown:text-error-500':
          error,
        'peer-focus:after:border-primary-400 peer-focus:text-primary-400 peer-focus:before:border-primary-400  peer-placeholder-shown:text-neutral-100':
          !error,
      }"
    >
      {{ props.label }}
    </span>

    <small v-if="error" class="text-error-500 mt-1 ml-3">
      {{ errorMessage }}
    </small>
  </div>
</template>

<style lang="postcss">
select {
  @apply h-14 px-3 py-2.5 border border-neutral-50 rounded-lg outline-0 bg-transparent text-base cursor-pointer;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none; /* Standard */
}
</style>
