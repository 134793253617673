import validate from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/pages/runtime/validate.js";
import abtest_45global from "/builds/trevo/web/middleware/abtest.global.ts";
import auth_45global from "/builds/trevo/web/middleware/auth.global.ts";
import cart_45clear_45global from "/builds/trevo/web/middleware/cart-clear.global.ts";
import cart_45coupon_45global from "/builds/trevo/web/middleware/cart-coupon.global.ts";
import location_45global from "/builds/trevo/web/middleware/location.global.ts";
import redirect_45old_45routes_45global from "/builds/trevo/web/middleware/redirectOldRoutes.global.ts";
import rejoinder_45global from "/builds/trevo/web/middleware/rejoinder.global.ts";
export const globalMiddleware = [
  validate,
  abtest_45global,
  auth_45global,
  cart_45clear_45global,
  cart_45coupon_45global,
  location_45global,
  redirect_45old_45routes_45global,
  rejoinder_45global
]
export const namedMiddleware = {
  "just-trevo": () => import("/builds/trevo/web/middleware/just-trevo.ts")
}