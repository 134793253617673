/**
 * Validates a Brazilian CPF (Tax ID) number.
 *
 * @param {string} value - The string containing the CPF number.
 * @returns {boolean} - Returns true if the CPF is valid, false otherwise.
 */
export default function isTaxId(value: string): boolean {
  if (typeof value !== 'string' || !value.trim())
    return false

  const cleanCPF = formatTaxId(value, { mask: false })

  if (cleanCPF.length !== 11 || /^(\d)\1+$/.test(cleanCPF))
    return false

  const calculateVerifier = (cpf: string, factor: number): number => {
    let sum = 0

    for (const digit of cpf) {
      sum += Number(digit) * factor--

      if (factor < 2)
        break
    }

    const remainder = 11 - (sum % 11)

    return remainder >= 10 ? 0 : remainder
  }

  const firstDigit = calculateVerifier(cleanCPF.slice(0, 9), 10)
  const secondDigit = calculateVerifier(cleanCPF.slice(0, 10), 11)

  return (
    Number(cleanCPF[9]) === firstDigit
    && Number(cleanCPF[10]) === secondDigit
  )
}
