import useCustomer from '@/modules/customer/composables/useCustomer'
import { testPages, testWeights } from '@/types/constants'
import { Cookie, TestABGroups } from '@/types/enums'
import { jwtDecode } from 'jwt-decode'

function _determineTestGroup(isControlSuffix: string): TestABGroups {
  const testGroups = Object.keys(testWeights) as (keyof typeof testWeights)[]
  const totalWeight = Object.values(testWeights).reduce((acc, weight) => acc + weight, 0)

  const remainingWeight = 1 - totalWeight

  let random = Math.random() * (totalWeight + remainingWeight)

  for (const group of testGroups) {
    const weight = testWeights[group]
    if (random < weight) {
      return `${group}${isControlSuffix}` as TestABGroups
    }
    random -= weight
  }

  return TestABGroups.IGNORE_AB_TEST
}

function _determineIsControlSuffix(): string {
  return Math.round(Math.random() * 1) ? 'Control' : ''
}

export default defineNuxtRouteMiddleware(async (to) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + 90)

  const abTest = useCookie(Cookie.TestAb, { expires })

  if (!useContent().value.hasAbTest) {
    abTest.value = TestABGroups.IGNORE_AB_TEST

    return
  }

  if (to.query.token && isJWT(to.query.token as string)) {
    const { ab_group } = jwtDecode<{ ab_group: TestABGroups }>(to.query.token as string)

    if (ab_group)
      abTest.value = ab_group as TestABGroups
  }

  const { customer } = useCustomer()

  const isTestPage = testPages.includes(to.name as string)

  const abTestUser = customer.value?.abGroup || null

  if (!abTest.value) {
    if (abTestUser) {
      abTest.value = abTestUser
    }
    else if (!isTestPage) {
      abTest.value = TestABGroups.IGNORE_AB_TEST
    }
    else {
      abTest.value = _determineTestGroup(_determineIsControlSuffix())
    }
  }

  if (!Object.values(TestABGroups).includes(abTest.value as TestABGroups) || (!!abTestUser && abTest.value !== abTestUser)) {
    abTest.value = TestABGroups.RESET_AB_TEST
  }

  setPageLayout((to.name as string)?.includes('checkout') ? 'checkout-new-journey' : 'new-journey')
})
