<script setup lang="ts">
import type { IUserIdentifiers } from '@/types/types'
import { Cookie, EventName, LocalStorageKey, TestABGroups, UserIdentifiersEvent } from '@/types/enums'
import validatorsRules from '@/validators/validatorsRules'
import { useVuelidate } from '@vuelidate/core'

defineProps({
  link: {
    type: String,
    required: false,
    default: '',
  },
  message: {
    type: String,
    default: '',
  },
})

const abTest = useCookie<TestABGroups>(Cookie.TestAb)

const showWhatsappButton = computed<boolean>(() => {
  if (abTest.value === TestABGroups.HIDE_WHATSAPP)
    return false

  const routeName = useRoute().name as string

  const PAGES_TO_SHOW = [
    'perfil',
    'perfil-meus-pedidos',
    'perfil-dados-cadastrais',
    'perfil-meus-cupons',
    'index',
    'location-exames-slug',
    'location-exames',
    'location',
    'quem-somos',
    'faq',
  ]

  if (PAGES_TO_SHOW.includes(routeName))
    return true

  return false
})

interface IForm {
  name: string
  phone: string
}

const user = reactive<IForm>({
  name: '',
  phone: '',
})

const rules = computed(() => ({
  name: {
    required: validatorsRules.required(),
    minLength: validatorsRules.size({
      size: 3,
      message: 'Informe no mínimo 3 caracteres',
    }),
  },
  phone: {
    required: validatorsRules.required(),
    size: validatorsRules.size({ size: 19, message: 'Telefone incompleto' }),
  },
}))

const validation = useVuelidate(rules, user)

const showMessage = ref(true)
const openedModal = ref(false)

const hasHovered = ref(false)

function handleHover() {
  if (!hasHovered.value) {
    showMessage.value = true
    hasHovered.value = true
  }
}

function closeMessage() {
  showMessage.value = false
  hasHovered.value = false
}

function toggleModal() {
  openedModal.value = !openedModal.value

  showMessage.value = false

  if (openedModal.value)
    track(EventName.OpenWppModal)
}

interface IChatInteractionData {
  name: string
  lastName: string
  phone: string
  deviceId: string | undefined
  sessionId: string | undefined
  userIdentifiers: IUserIdentifiers
}
async function createUserButton() {
  if (validation.value.$invalid)
    return

  const [firstName, ...rest] = user.name.split(' ')
  const lastName = rest.join(' ')

  const formattedPhone = formatPhone(user.phone)
  setAdData(user.phone)

  const payload: IChatInteractionData = {
    name: firstName,
    lastName,
    phone: formattedPhone,
    deviceId: useTrackMixpanelDistinctId().value,
    sessionId: useTrackGoogleSessionId().value,
    userIdentifiers: {
      userPseudoId: useTrackGoogleClientId().value,
      event: UserIdentifiersEvent.CHAT_INTERACTION,
      gclid: getClickIdentifier(LocalStorageKey.GCLID),
      gbraid: getClickIdentifier(LocalStorageKey.GBRAID),
      wbraid: getClickIdentifier(LocalStorageKey.WBRAID),
      abGroup: abTest.value,
      deviceId: useTrackMixpanelDistinctId().value,
      sessionId: useTrackGoogleSessionId().value,
    },
  }

  track(EventName.InitWppConversation, {
    name: payload.name,
    phone: payload.phone,
  })

  await chatInteraction(payload)

  openedModal.value = false
}

async function chatInteraction(body: IChatInteractionData) {
  try {
    await $fetch('/v2/recommendations/lead/chat-interaction', {
      baseURL: useRuntimeConfig().public.api.awsGateway,
      method: 'POST',
      body,
    })
  }
  catch {
    toast({
      severity: 'error',
      summary: 'Erro',
      detail: 'Não foi possível iniciar a conversa. Tente novamente mais tarde.',
    })
  }
}

function openWppLink() {
  window.location.href = getWhatsAppLink()
}

async function handleClickSendMessage() {
  if (validation.value.$invalid)
    return
  await createUserButton()
  openWppLink()
}

onNuxtReady(() => {
  setTimeout(() => {
    showMessage.value = false
  }, 3000)
})
</script>

<template>
  <div v-if="showWhatsappButton" id="trevo-whatsapp-button" class="fixed bottom-10 lg:bottom-8 right-4 lg:right-8 z-20">
    <div class="relative w-[50px] h-[50px]">
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight animate__faster"
        leave-active-class="animate__animated animate__fadeOutRight animate__faster"
        mode="out-in"
      >
        <div
          v-show="showMessage"
          class="max-w-[280px] w-[280px] sm:max-w-[357px] sm:w-[357px] p-6 bg-primary-100 shadow-lg rounded-lg absolute -top-24 right-4"
        >
          <button
            aria-label="Botão para fechar a mensagem"
            class="flex items-center justify-center w-6 h-6 lg:w-10 lg:h-10 rounded-full bg-primary-400 absolute -top-3 -right-3 lg:-top-5 lg:-right-5"
            type="button"
            @click="closeMessage"
          >
            <Icon
              name="mdi-close"
              class="w-3 h-3 lg:w-6 lg:h-6 text-white"
            />
          </button>
          <div
            class="flex items-center gap-4 text-xs sm:text-sm text-primary-500"
          >
            <Img
              v-if="isTrevo()"
              classes="min-w-[16px] w-4 xs:min-w-[24px] lg:w-6"
              src="https://web-cdn.saudetrevo.com.br/trevo/logo_miniatura.svg"
              alt="Logo da trevo"
              width="24"
              height="24"
            />
            Estamos aqui para atender às suas necessidades de saúde!
          </div>
        </div>
      </transition>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight animate__faster"
        leave-active-class="animate__animated animate__fadeOutRight animate__faster"
        mode="out-in"
      >
        <form
          v-if="openedModal"
          class="w-[280px] sm:w-[357px] text-sm absolute -top-96 lg:-top-96 right-4 shadow-lg bg-white text-neutral-200 rounded-lg"
        >
          <button
            aria-label="Botão para fechar ou abrir o modal de whatsapp"
            type="button"
            class="flex items-center justify-center w-6 h-6 lg:w-10 lg:h-10 rounded-full bg-neutral-300 absolute -top-3 -right-3 lg:-top-5 lg:-right-5"
            @click.prevent="toggleModal"
          >
            <Icon
              name="mdi:close"
              class="w-3 h-3 lg:w-6 lg:h-6 text-white"
            />
          </button>
          <div class="px-3 pt-6">
            <p>
              Olá! Que tal aproveitar um orçamento gratuito para seus exames? Para iniciarmos uma conversa sem compromisso, por favor, compartilhe seu nome e telefone.
            </p>
          </div>
          <div class="pt-2 flex flex-col gap-2 px-3">
            <TInputText
              id="name"
              v-model="user.name"
              label="Nome"
              class="mt-2"
              :max="80"
              :validation="validation.name"
              @change="validation.name.$touch"
            />

            <TInputText
              id="phone"
              v-model="user.phone"
              class="mt-2"
              label="Telefone"
              mask="+55 (##) 9####-####"
              :max="19"
              :validation="validation.phone"
              @change="validation.phone.$touch"
            />
          </div>
          <!-- <p v-if="validation.$invalid" class="text-xs text-error-500 text-center pt-2">
              Por favor, verifique as informações e tente novamente.
            </p> -->
          <div
            class="flex items-center justify-center gap-2 px-2 py-3 text-xs sm:text-sm"
          >
            <button
              class="rounded-lg bg-neutral-300 text-white px-1 py-2 w-full text-center"
              type="button"
              @click.prevent="handleClickSendMessage"
            >
              Iniciar conversa
            </button>
            <!-- <button
                class="rounded-lg border border-neutral-50 text-primary-400 px-1 py-2 w-full"
                type="button"
                @click.prevent="handleClickCallLatter"
              >
                Falar mais tarde
              </button> -->
          </div>
        </form>
      </transition>

      <button
        aria-label="Botão para entrar em contato pelo WhatsApp"
        class="flex items-center justify-center min-w-[50px] min-h-[50px] rounded-full bg-whatsapp text-white hover:scale-110 duration-300"
        type="button"
        @mouseover="handleHover"
        @click="toggleModal"
      >
        <span class="sr-only">Botão para entrar em contato pelo WhatsApp</span>
        <Icon name="mdi:whatsapp" color="currentColor" class="w-6 h-6" />
        <span>{{ message }}</span>
      </button>
    </div>
  </div>
</template>
