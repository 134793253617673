import type { ISessionTokens } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

export default async (): Promise<ISessionTokens | null> => {
  try {
    const refreshToken = useLocalStorage(LocalStorageKey.RefreshToken, '')

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const response = await $fetch<ISessionTokens>('/v1/customer/users/refresh_token', {
      method: 'POST',
      baseURL,
      body: { refresh_token: refreshToken.value },
    })

    return snakeToCamel(response)
  }
  catch {
    return null
  }
}
