import revive_payload_client_Gk0tQBO_ZJ9VHxK9Te3vVrdTd87CbPJiaqPP7CiwGRg from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jangV20fcZ9POjnUzd8lQ_cETgvVBA6lZApbmCg5YGc from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bDMI_9ll5sl2DO_ENh6Q6ANDBQG8gMH5l4RYkT5aWto from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/builds/trevo/web/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_5Q8cK0mNr6JDehTqWZPtG231W7N1jNoMysIe_ZfU_ZY from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SBzjAqVcfxz7xJSBJPyn2Uipy2E_8V1mUTGRNsaCraU from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_zFmAjagaK5HGGmYCI7wPTZZmTngOj_PKYqX_gIR_lfA from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/trevo/web/.nuxt/components.plugin.mjs";
import prefetch_client_o58LBPLer_ZD4eLX0QsbH7TtB_ZAa0GgwFRrtnHUmd0 from "/builds/trevo/web/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_udl3fdkcqjqt2cbfqlzlk3of4m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0 from "/builds/trevo/web/.nuxt/primevue-plugin.mjs";
import plugin_client_N5lCuvT25XA3anU1Xgz3Pv4WwcsgkHoxc6kzDEe4VLk from "/builds/trevo/web/node_modules/.pnpm/@primevue+nuxt-module@4.3.3_@babel+parser@7.27.0_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4 from "/builds/trevo/web/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_xLayBzoNrfK48PSxowKkQ_clx1Kz5WWEJor61_l5N3Y from "/builds/trevo/web/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.14_jiti@2.4.2_terser@5.39.0_yam_luts34kzr22ykoty2xemq26nga/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/builds/trevo/web/plugins/api.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/builds/trevo/web/plugins/error-handler.ts";
import maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo from "/builds/trevo/web/plugins/maska.ts";
import primevue_locale_GN3rkzxdRL5GFb1CAseTlx1wKS90tvTsKXTeakYNBtQ from "/builds/trevo/web/plugins/primevue-locale.ts";
import primevue_toastservice_oQh3f9_HszrJkGQeLRPoGwcRq3P7c9bXAXuqjRubVzQ from "/builds/trevo/web/plugins/primevue-toastservice.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/builds/trevo/web/plugins/sentry.client.ts";
export default [
  revive_payload_client_Gk0tQBO_ZJ9VHxK9Te3vVrdTd87CbPJiaqPP7CiwGRg,
  unhead_jangV20fcZ9POjnUzd8lQ_cETgvVBA6lZApbmCg5YGc,
  router_bDMI_9ll5sl2DO_ENh6Q6ANDBQG8gMH5l4RYkT5aWto,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client_5Q8cK0mNr6JDehTqWZPtG231W7N1jNoMysIe_ZfU_ZY,
  navigation_repaint_client_SBzjAqVcfxz7xJSBJPyn2Uipy2E_8V1mUTGRNsaCraU,
  chunk_reload_client_zFmAjagaK5HGGmYCI7wPTZZmTngOj_PKYqX_gIR_lfA,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_o58LBPLer_ZD4eLX0QsbH7TtB_ZAa0GgwFRrtnHUmd0,
  primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0,
  plugin_client_N5lCuvT25XA3anU1Xgz3Pv4WwcsgkHoxc6kzDEe4VLk,
  plugin_client_NwdnULXAwc_v6m7WVDkQFlGmJAIsKnEFEqOIwKwj3N4,
  plugin_xLayBzoNrfK48PSxowKkQ_clx1Kz5WWEJor61_l5N3Y,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo,
  primevue_locale_GN3rkzxdRL5GFb1CAseTlx1wKS90tvTsKXTeakYNBtQ,
  primevue_toastservice_oQh3f9_HszrJkGQeLRPoGwcRq3P7c9bXAXuqjRubVzQ,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE
]