import { Cookie, LocationType } from '@/types/enums'

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    if (!useLocation().value)
      useLocation().value = await $fetch('/api/location', { query: { location: to.params.location || 'sao-paulo-sp' } })

    const locationParam = useCookie(Cookie.LocationParam)

    if (to.params.location) {
      locationParam.value = to.params.location as string

      return
    }

    if (useLocation().value) {
      const locationParamOption = {
        [LocationType.CITY]: useLocation().value?.slug,
        [LocationType.GEOLOCATION]: `${useLocation().value?.lat},${useLocation().value?.lng}`,
        [LocationType.ZIP_CODE]: useLocation().value?.postalCode,
      }

      locationParam.value = locationParamOption[useLocation().value?.type] || 'sao-paulo-sp'

      return
    }

    locationParam.value = 'sao-paulo-sp'
  }
})
