<script lang="ts" setup>
import type { TestABGroups } from '@/types/enums'
import type { IUserFromCartShared } from '@/types/types'
import { Cookie, EventName, LocalStorageKey, SessionStorageKey, UserIdentifiersEvent } from '@/types/enums'
import { Form, FormField } from '@primevue/forms'
import { zodResolver } from '@primevue/forms/resolvers/zod'
import InputMask from 'primevue/inputmask'
import Message from 'primevue/message'
import { z } from 'zod'

const emit = defineEmits(['close', 'sendToken'])

const abTest = useCookie<TestABGroups>(Cookie.TestAb)
const userFromCartShared = useSessionStorage(SessionStorageKey.UserFromCartShared, {} as IUserFromCartShared)

const { showSignInModal, signInModalWithoutGoogle, loading } = useAuth()

const formPhone = ref()

const form = reactive({
  phone: '',
})

const resolver = zodResolver(
  z.object({
    phone: z
      .string({ message: 'Número de telefone inválido.' })
      .min(19, { message: 'Número de telefone inválido.' }),
  }),
)

async function sendToken() {
  track(EventName.SendToken, { phone: formatPhone(form.phone) })

  const sendMessage = await useAuthSendToken({
    phone: formatPhone(form.phone),
    userIdentifiers: {
      userPseudoId: useTrackGoogleClientId().value,
      event: UserIdentifiersEvent.LOGIN,
      gclid: getClickIdentifier(LocalStorageKey.GCLID),
      gbraid: getClickIdentifier(LocalStorageKey.GBRAID),
      wbraid: getClickIdentifier(LocalStorageKey.WBRAID),
      abGroup: abTest.value,
      deviceId: useTrackMixpanelDistinctId().value,
      sessionId: useTrackGoogleSessionId().value,
    },
  })
  if (!sendMessage) {
    return toast({
      severity: 'error',
      summary: 'error',
      detail: 'Falha ao enviar código de verificação SMS.',
    })
  }

  emit('sendToken', form.phone)
  showSignInModal.value = false
}

async function submit(): Promise<void> {
  formPhone.value.validate()

  if (formPhone.value.valid) {
    return sendToken()
  }
}

function signInGoogle(response: any) {
  if (response.error && response.error.value) {
    errorTracker(response.error)
    return
  }

  if (response.data && response.data.value.accessToken) {
    useLocalStorage(LocalStorageKey.AccessToken, '').value = response.data.value.accessToken
    useLocalStorage(LocalStorageKey.RefreshToken, '').value = response.data.value.refreshToken

    emit('close')
  }

  const { customer } = useCustomer()

  customer.value = response.data.value
}
const content = useContent()

onNuxtReady(() => {
  form.phone = userFromCartShared.value.phoneTo
})
</script>

<template>
  <div class="p-4 text-sm lg:min-w-[600px]">
    <div class="flex items-center justify-between">
      <h3 class="text-primary-400 text-xl">
        Informações de entrada
      </h3>

      <Icon
        name="mdi:close"
        class="text-primary-400 size-4 cursor-pointer"
        @click="showSignInModal = false"
      />
    </div>
    <p class="mt-3 font-semibold font-sora text-neutral-300 text-left w-full">
      Qual o número do seu celular?
    </p>

    <Form ref="formPhone" :resolver class="flex flex-col items-center max-w-[400px] mx-auto">
      <FormField v-slot="$field" name="phone" class="w-full">
        <label for="phone" class="flex flex-col mt-4 gap-1 w-full">
          <span class="text-neutral-200 font-sora">Celular</span>
          <InputMask
            id="phone"
            v-model="form.phone"
            mask="+55 (99) 9 9999-9999"
            placeholder="Insira o seu número de celular"
            data-testid="sign-in-phone"
            name="phone"
            class="w-full"
          />
          <Message
            v-if="$field?.invalid"
            severity="error"
            size="small"
            variant="simple"
          >
            {{ $field.error?.message }}
          </Message>
        </label>
      </FormField>

      <div class="mt-4 rounded-lg p-4">
        <p
          class="text-center text-neutral-100"
        >
          <span class="block">
            Ao clicar em
            <strong class="font-bold">Enviar código SMS</strong>, concordo em
            receber mensagens de texto de acordo com os
            <NuxtLink
              no-prefetch
              class="font-bold cursor-pointer"
              to="/termos-e-condicoes"
            >
              Termos e Condições
            </NuxtLink>
            {{ content.labArticle }} {{ content.sellerName }}.
          </span>
        </p>
      </div>

      <Button
        class="mt-7 w-full lg:w-52"
        :loading="loading"
        :disabled="form.phone?.length < 19"
        data-testid="request-token-button"
        @click="submit"
      >
        Enviar código SMS
      </Button>

      <template v-if="!signInModalWithoutGoogle && isTrevo()">
        <DividerText class="my-4">
          ou
        </DividerText>

        <ButtonGoogle
          context="signin"
          @callback="signInGoogle"
        />
      </template>
    </Form>
  </div>
</template>
