import type { IValidatorOptions, IValidatorOptionsMin, IValidatorOptionsSize } from '@/types/types'
import { email, helpers, minLength, required } from '@vuelidate/validators'

export default {
  required: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Campo obrigatório.'

    return helpers.withMessage(message, required)
  },

  min: (
    validatorOptions: IValidatorOptionsMin = {} as IValidatorOptionsMin,
  ) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : `Informe no mínimo ${validatorOptions.min} caracteres.`

    return helpers.withMessage(message, minLength(validatorOptions.min))
  },

  email: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Email inválido'

    return helpers.withMessage(message, email)
  },

  fullname: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Nome completo inválido.'

    return helpers.withMessage(message, (fullname: string) => {
      const [name, ...rest] = fullname.split(' ')
      const surname = rest.join(' ')

      return !!(name && surname)
    })
  },

  size: (
    validatorOptions: IValidatorOptionsSize = {} as IValidatorOptionsSize,
  ) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : `Este campo deve conter ${validatorOptions.size} caracteres`

    return helpers.withMessage(message, minLength(validatorOptions.size))
  },

  alpha: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Este campo deverá conter apenas caracteres alfabéticos'

    return helpers.withMessage(message, (v: string) => /^[a-z\s]*$/i.test(v))
  },

  taxId: (validatorOptions: IValidatorOptions = {}) => {
    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Digite um CPF válido'

    return helpers.withMessage(message, isTaxId)
  },

  birthdate: (validatorOptions: IValidatorOptions = {}) => {
    const isBirthDate = (value: string) => {
      const [day, month, year] = value.split('/').map(Number)

      if (year < 1900 || year > new Date().getFullYear())
        return false

      if (month < 1 || month > 12)
        return false

      if (day < 1 || day > 31)
        return false

      if (month === 4 || month === 6 || month === 9 || month === 11) {
        if (day < 1 || day > 30)
          return false
      }

      if (month === 2) {
        const maxDays = year % 4 === 0 ? 29 : 28

        if (day < 1 || day > maxDays)
          return false
      }

      return true
    }

    const message = validatorOptions.message
      ? validatorOptions.message
      : 'Data de nascimento inválida'

    return helpers.withMessage(message, isBirthDate)
  },

  custom: () => {
    return helpers.withMessage('', () => false)
  },

  serverError: (
    errors: Ref<Record<string, string | undefined>>,
    fieldName: string,
  ) => {
    return helpers.withMessage(
      () => errors.value[fieldName] || '',
      () => !errors.value[fieldName],
    )
  },

  cardExpiry: () => {
    return helpers.withMessage('Data de expiração inválida', (value: string) => {
      if (!value || value.length !== 5)
        return false

      const [month, year] = value.split('/').map(Number)

      const currentYear = new Date().getFullYear() % 100

      if (year < currentYear)
        return false

      const currentMonth = new Date().getMonth() + 1

      if (year === currentYear && month < currentMonth)
        return false

      if (month < 1 || month > 12)
        return false

      return true
    })
  },
}
