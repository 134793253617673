import { LocalStorageKey } from '@/types/enums'

export async function setRejoinder(): Promise<void> {
  try {
    const { id: userId } = useCustomer().customer.value

    if (!userId)
      return

    const response = await $fetch('/api/procedure/schedule', {
      headers: {
        AuthToken: useLocalStorage(LocalStorageKey.AccessToken, '').value,
      },
      query: { userId },
    })

    if (response) {
      const { rejoinder, rejoinderModal } = useRejoinder()

      rejoinder.value = response
      rejoinderModal.value = !!(response && Object.keys(response).length)
    }
  }
  catch (error) {
    captureException(error)
  }
}
