import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()
  const { sentryDsn, sellerDns } = useRuntimeConfig().public.buildContent

  if (!sentryDsn)
    return

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDsn,
    environment: sentry.environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [/.*/],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: ['http://localhost:3000', sellerDns, `https://${sellerDns}`],

    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  })
})
