<script setup lang="ts">
import type { IPromo } from '@/types/types'

const props = defineProps({
  cupom: {
    type: Object as PropType<IPromo>,
    default: () => {},
  },
  modelValue: {
    type: String,
    default: '',
  },
  unchecked: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

function setDiscountMessage(type: string, value: number) {
  if (type === 'percent')
    return `${value}%`

  return `R$ ${value}`
}

function selectCupom() {
  emit('update:modelValue', props.cupom.code)
}
</script>

<template>
  <label id="cupom" class="group cursor-pointer flex items-center justify-between border border-neutral-100 rounded-lg h-[76px] px-6 py-2 hover:border-primary-400 duration-300 xl:flex-1">
    <div class="flex items-center gap-3">
      <div class="flex items-center justify-center size-10 rounded-full bg-primary-400">
        <Icon name="mdi:ticket-percent-outline" class="size-6 text-bg-50" />
      </div>
      <div class="flex flex-col">
        <p class="uppercase text-primary-400 text-xs font-semibold">{{ cupom.code }}</p>
        <p class="uppercase text-primary-500 font-bold">OFF {{ setDiscountMessage(cupom.discountType, cupom.value) }}</p>
        <P class="uppercase text-neutral-200 text-xs font-semibold">{{ cupom.message }}</P>
      </div>
    </div>

    <input
      :checked="!!props.modelValue && props.cupom.code === props.modelValue && !props.unchecked"
      type="radio"
      :value="props.cupom"
      name="cupom"
      class="peer mr-4 appearance-none bg-white border-2 border-neutral-300 cursor-pointer rounded-full w-6 h-6 relative peer shrink-0 checked:bg-white checked:text-bg-50 checked:border checked:border-primary-400 checked:before:content-[''] before:absolute before:inset-1 before:bg-white checked:before:bg-primary-400 before:rounded-full flex justify-center items-center text-base group-hover:border-primary-400 duration-300"
      @change="selectCupom"
    />
  </label>
</template>
