<script setup lang="ts">
defineProps({
  text: {
    type: String,
    default: '',
  },

  buttonText: {
    type: String,
    default: 'Falar com a Concierge',
  },
})

defineEmits(['close'])

const wppLink = getWhatsAppLink()
</script>

<template>
  <Modal without-header size="small" @close="$emit('close')">
    <template #content>
      <div class="flex flex-col items-center pb-28">
        <p class="flex flex-col items-center text-primary-400 text-2xl">
          <span class="text-center">{{ text }}</span>
        </p>
        <NuxtLink
          :to="wppLink"
          external
          no-prefetch
          target="_blank"
        >
          <Button class="mt-8" full>
            <Icon name="mdi:whatsapp" class="w-4 h-4 mr-2 text-white" />

            <span> {{ buttonText }} </span>
          </Button>
        </NuxtLink>
      </div>
    </template>
  </Modal>
</template>
