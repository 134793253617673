import { defineNuxtPlugin } from '#app'
import { usePrimeVue } from 'primevue/config'

export default defineNuxtPlugin(() => {
  const primevue = usePrimeVue()

  if (primevue.config.locale) {
    primevue.config.locale.accept = 'Sim'
    primevue.config.locale.reject = 'Não'
    primevue.config.locale.startsWith = 'Começa com'
    primevue.config.locale.contains = 'Contém'
    primevue.config.locale.notContains = 'Não contém'
    primevue.config.locale.endsWith = 'Termina com'
    primevue.config.locale.equals = 'Igual a'
    primevue.config.locale.notEquals = 'Diferente de'
    primevue.config.locale.noFilter = 'Sem filtro'
    primevue.config.locale.lt = 'Menor que'
    primevue.config.locale.lte = 'Menor ou igual a'
    primevue.config.locale.gt = 'Maior que'
    primevue.config.locale.gte = 'Maior ou igual a'
    primevue.config.locale.dateIs = 'Data é'
    primevue.config.locale.dateIsNot = 'Data não é'
    primevue.config.locale.dateBefore = 'Data é antes'
    primevue.config.locale.dateAfter = 'Data é depois'
    primevue.config.locale.clear = 'Limpar'
    primevue.config.locale.apply = 'Aplicar'
    primevue.config.locale.matchAll = 'Corresponder a todos'
    primevue.config.locale.matchAny = 'Corresponder a qualquer'
    primevue.config.locale.addRule = 'Adicionar Regra'
    primevue.config.locale.removeRule = 'Remover Regra'
    primevue.config.locale.choose = 'Escolher'
    primevue.config.locale.upload = 'Enviar'
    primevue.config.locale.cancel = 'Cancelar'
    primevue.config.locale.dayNames = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
    primevue.config.locale.dayNamesShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
    primevue.config.locale.dayNamesMin = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    primevue.config.locale.monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    primevue.config.locale.monthNamesShort = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    primevue.config.locale.today = 'Hoje'
    primevue.config.locale.weekHeader = 'Sem'
    primevue.config.locale.weak = 'Fraco'
    primevue.config.locale.medium = 'Médio'
    primevue.config.locale.strong = 'Forte'
    primevue.config.locale.passwordPrompt = 'Digite a senha'
    primevue.config.locale.emptyFilterMessage = 'Nenhum resultado encontrado'
    primevue.config.locale.emptyMessage = 'Nenhum item disponível'
  }
})
