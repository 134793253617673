interface IContent {
  sellerId: string
  sellerName: string
  sellerDns: string
  sellerInfo: string
  citySlugDefault: string
  theme: string
  companyName: string
  taxId: string
  primaryColor: string
  secondaryColor: string
  tertiaryColor: string
  hasAbTest: boolean
  multiLocation: boolean
  multiSeller: boolean
  labArticle: string
  footerOpeningHours: string
  whatsappNumber: string
  gtag: string
  favicon: string
  logoWhiteUrl: string
  logoDarkUrl: string
  sentryDsn: string
  instagramUrl: string
  linkedinUrl: string
  xUrl: string
  blogUrl: string
  reclameAquiUrl: string
  facebookUrl: string
  clientDescription: string
}

export default () => useState<IContent>('build-content', () => useRuntimeConfig().public.buildContent as unknown as IContent)
