<script setup lang="ts">
import { EventName, LocalStorageKey } from '@/types/enums'

if (import.meta.client) {
  useAuthGlobally()
  useCustomerGlobally()
}

const route = useRoute()
const phone = ref('')
const scheduling = ref({})
const gclid = useLocalStorage(LocalStorageKey.GCLID, '')
const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')
const pageName = computed(() => route.name === 'index' ? 'home' : route.name)

const { showSignInModal, showSignUpModal, showTokenModal, logout } = useAuth()

const fullPageLoading = useFullPageLoading()

function handleShowTokenModal(currentPhone: string) {
  phone.value = currentPhone

  showTokenModal.value = true
  showSignInModal.value = false
}

const { customer } = useCustomer()
watch(
  [
    customer,
  ],
  () => {
    try {
      const baseURL = useRuntimeConfig().public.api.awsGateway

      if (!customer.value?.id || !gclid.value)
        return

      return $fetch(`/v2/customer/users/${customer.value.id}/device`, {
        baseURL,
        method: 'POST',
        body: JSON.stringify({
          gclid: getClickIdentifier(LocalStorageKey.GCLID),
          gbraid: getClickIdentifier(LocalStorageKey.GBRAID),
          wbraid: getClickIdentifier(LocalStorageKey.WBRAID),
          device_id: useTrackMixpanelDistinctId().value,
          session_id: useTrackGoogleSessionId().value,
          user_pseudo_id: useTrackGoogleClientId().value,
        }),
        headers: {
          Authtoken: accessToken.value,
        },
      })
    }
    catch (error) {
      return error
    }
  },
  {
    deep: true,
  },
)

function closeModals() {
  logout()
  showSignInModal.value = false
  showTokenModal.value = false
  showSignUpModal.value = false
}

const currentModalComponent = computed(() => {
  if (showSignInModal.value && !isNewJourney())
    return resolveComponent('CustomerSignInModal')

  if (showSignInModal.value && isNewJourney())
    return resolveComponent('ModalAuthSignInNewJourney')

  if (showSignUpModal.value)
    return resolveComponent('CustomerSignUpModal')

  if (showTokenModal.value && !isNewJourney())
    return resolveComponent('CustomerTokenModal')

  return null as never
})

watch(
  pageName,
  (newPageName, oldPageName) => {
    if (newPageName !== oldPageName) {
      track(EventName.PageView, { page: newPageName })
    }
  },
  { immediate: true },
)

onNuxtReady(() => {
  setClickIdentifiers()
  track(EventName.PageView, { page: pageName.value })
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />

      <PrimeToast />

      <TFullPageLoading v-if="fullPageLoading" />
      <CartPromoSideSheet :show-promo="usePromoModalActiveState().value" @close="usePromoModalClose" />

      <ProfilePromoAdded />

      <Dialog
        v-if="!isNewJourney()"
        :show="showSignInModal || showSignUpModal || showTokenModal"
        @close="closeModals"
      >
        <transition name="fade" mode="out-in">
          <component
            :is="currentModalComponent"
            :phone="phone"
            :scheduling="scheduling"
            @close="closeModals"
            @send-token="handleShowTokenModal"
          />
        </transition>
      </Dialog>

      <PrimeDialog
        v-if="isNewJourney()"
        :visible="showSignInModal || showSignUpModal"
        :show-header="false"
        modal
        :draggable="false"
      >
        <component
          :is="currentModalComponent"
          :phone="phone"
          :scheduling="scheduling"
          @close="closeModals"
          @send-token="handleShowTokenModal"
        />
      </PrimeDialog>

      <PrimeDialog
        v-if="isNewJourney()"
        v-model:visible="showTokenModal"
        :show-header="false"
        closable
        dismissableMask
        modal
        :draggable="false"
      >
        <ModalAuthTokenNewJourney
          :phone="phone"
          @close="showTokenModal = false"
        />
        <component
          :is="currentModalComponent"
          :scheduling="scheduling"
          @send-token="handleShowTokenModal"
        />
      </PrimeDialog>

      <ButtonWhatsApp />
    </NuxtLayout>
  </div>
</template>
