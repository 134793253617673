import type { ICart, ISaveCartParams } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

export default async function saveCart({ body, loading, onSuccess, onError }: ISaveCartParams = { loading: ref(false), onSuccess: () => { }, onError: () => { }, body: {} as ICart }): Promise<void> {
  try {
    const cart = useLocalStorage(LocalStorageKey.Cart, {} as ICart)
    const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

    if (!accessToken.value || !cart.value.cartDetails?.length) {
      cart.value = { ...cart.value, ...body }
      onSuccess()
      return
    }

    loading.value = true

    if (body.address?.number)
      body.address.number = body.address?.number.toString()

    const response = await $fetch<ICart[]>(`/v2/recommendations/users/${useCustomer().customer.value.id}/cart`, {
      baseURL: useRuntimeConfig().public.api.awsGateway,
      method: 'PATCH',
      headers: {
        Authtoken: accessToken.value,
      },
      body: camelToSnake({
        ...cart.value,
        ...body,
      }),
    })

    nextTick(() => {
      if (response && response.length) {
        cart.value = { ...response[0] }

        if (response[0]?.healthPlan?.id)
          cart.value.healthPlanId = response[0]?.healthPlan?.id
      }
    })

    if (onSuccess)
      onSuccess()
  }
  catch (error: unknown) {
    errorTracker(error)

    if (onError)
      onError()
  }
  finally {
    loading.value = false
  }
}
