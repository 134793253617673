<script setup lang="ts">
useSeoMeta({
  title: 'Ops, algum erro aconteceu',
})

function handleGoToHome() {
  window.location.href = '/'
}
</script>

<template>
  <NuxtLayout name="default">
    <div class="container mx-auto flex flex-col px-6 xl:px-0 relative">
      <div class="flex flex-col xl:flex-row items-center py-9">
        <div class="flex flex-col items-center xl:items-start gap-3 pb-9 xl:w-1/2">
          <span class="text-5xl xl:text-6xl font-bold text-primary-400">
            (╥﹏╥)
          </span>
          <p class="text-2xl xl:text-4xl text-tertiary-400">
            Ué! Algum erro aconteceu,
          </p>
          <p class="text-base xl:text-2xl text-primary-400">
            tente novamente ou fale com a concierge
          </p>
          <Button @click="handleGoToHome">
            Voltar para a Home
          </Button>
        </div>
        <div class="xl:w-1/2">
          <Img
            src="https://web-cdn.saudetrevo.com.br/illustrations/erro_na_pagina.svg"
            alt="Ilustraçaõ de erro"
            width="516"
            height="516"
            class="w-[344px] xl:w-[516px] m-auto"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
