<script lang="ts" setup>
import type { ICustomer, IUserFromCartShared } from '@/types/types'
import { SessionStorageKey } from '@/types/enums'
import validatorsRules from '@/validators/validatorsRules'
import { useVuelidate } from '@vuelidate/core'

defineEmits(['close'])

interface IForm {
  name: string
  phone: string
  email: string
  birthDate: string
  gender: string | undefined
  taxId: string | undefined
}

const { logout } = useAuth()
const content = useContent()

const { customer, loading, genderOptions, errors } = useCustomer()
const userFromCartShared = useSessionStorage(SessionStorageKey.UserFromCartShared, {} as IUserFromCartShared)

const form = reactive<IForm>({
  name: `${customer.value.name || ''} ${customer.value.lastName || ''}`.trim(),
  phone: customer.value.phone || '',
  email: customer.value.email || '',
  birthDate: dateToBr(customer.value.birthDate),
  gender: customer.value.gender ? customer.value.gender : '',
  taxId: customer.value.taxId,
})

const rules = computed(() => ({
  name: {
    required: validatorsRules.required(),
    fullname: validatorsRules.fullname(),
    minLength: validatorsRules.size({
      size: 4,
      message: 'Informe no mínimo 4 caracteres',
    }),
  },
  phone: {
    required: validatorsRules.required(),
    min: validatorsRules.min({ min: 19 }),
    serverError: validatorsRules.serverError(errors, 'phone'),
  },
  email: {
    required: validatorsRules.required(),
    email: validatorsRules.email(),
    serverError: validatorsRules.serverError(errors, 'email'),
  },
  birthDate: {
    required: validatorsRules.required(),
    minLength: validatorsRules.size({
      size: 10,
      message: 'Informe a data completa',
    }),
    birthdate: validatorsRules.birthdate(),
  },
  gender: {
    required: validatorsRules.required(),
  },
  taxId: {
    required: validatorsRules.required(),
    taxId: validatorsRules.taxId(),
    serverError: validatorsRules.serverError(errors, 'taxId'),
  },
}))

const validation = useVuelidate(rules, form)

async function saveUser() {
  const [name, ...rest] = form.name.split(' ')
  const lastName = rest.join(' ')

  const payload: ICustomer = {
    name,
    lastName,
    phone: formatPhone(form.phone),
    email: form.email,
    gender: form.gender,
    taxId: form.taxId,
    birthDate: dateToEn(form.birthDate || ''),
  }

  if (customer.value.exp)
    delete customer.value.exp

  customer.value = { ...customer.value, ...payload }
}

function cleanErrors(id: keyof ICustomer) {
  errors.value[id] = undefined
  validation.value[id].$touch()
}

onNuxtReady(() => {
  validation.value.$reset()

  form.name = `${userFromCartShared.value.name || ''} ${userFromCartShared.value.lastName || ''}`.trim()
})
</script>

<template>
  <div class="py-3 xl:py-14 xl:px-8" data-testid="sign-up-modal">
    <section class="flex flex-col items-center justify-center px-4 text-neutral-200 text-center w-full lg:px-0">
      <h3 class="text-primary-500 text-3xl">
        Cadastro
      </h3>

      <p class="text-center mt-3 text-primary-500 text-sm">
        Solicitamos apenas as informações essenciais para o seu cadastro. Essas informações também farão parte do seu
        prontuário médico.
      </p>

      <span class="text-primary-500 text-xs mt-4 underline font-semibold cursor-pointer" @click="logout">
        Não sou eu
      </span>

      <form class="flex flex-col items-center justify-center gap-2 w-full mt-6">
        <TInputText
          id="name"
          v-model="form.name"
          label="Nome Completo"
          class="mt-3"
          :max="80"
          :validation="validation.name"
          @change="cleanErrors('name')"
        />
        <div class="flex flex-col gap-4 w-full lg:flex-row justify-center">
          <TInputText
            id="phone"
            v-model="form.phone"
            mask="+55 (##) 9####-####"
            disabled
            :max="19"
            label="Celular"
            :validation="validation.phone"
            @change="cleanErrors('phone')"
          />

          <TInputText
            id="cpf"
            v-model="form.taxId"
            mask="###.###.###-##"
            label="CPF"
            :validation="validation.taxId"
            @change="cleanErrors('taxId')"
          />
        </div>

        <TInputText
          id="email"
          v-model="form.email"
          label="E-mail"
          :validation="validation.email"
          @change="cleanErrors('email')"
        />

        <div class="flex flex-col gap-4 w-full lg:flex-row justify-center">
          <TInputText
            id="birt"
            v-model="form.birthDate"
            label="Data de Nascimento"
            mask="##/##/####"
            :validation="validation.birthDate"
            @change="cleanErrors('birthDate')"
          />

          <InputSelect
            id="gender"
            v-model="form.gender"
            label="Gênero"
            class="w-full"
            :options="genderOptions"
          />
        </div>

        <p class="flex gap-2 mt-2 pl-3 text-xs font-semibold text-neutral-400">
          <span>
            Ao clicar em <b>Cadastrar</b>, concordo com os
            <NuxtLink no-prefetch to="/termos-e-condicoes" class="text-information-500 cursor-pointer">
              Termos de Serviço
            </NuxtLink>
            e
            <NuxtLink no-prefetch to="/politicas-de-privacidade" class="text-information-500 cursor-pointer">
              Política de Privacidade
            </NuxtLink>
            d{{ content.labArticle }} {{ content.sellerName }}.
          </span>
        </p>

        <div class="w-full px-6 lg:px-0 mt-16 lg:mt-14">
          <Button
            type="submit"
            :disabled="validation.$invalid"
            :loading="loading"
            class="w-full lg:w-[296px] mb-2 lg:mx-auto"
            @click.prevent="saveUser"
          >
            Cadastrar
          </Button>
        </div>
      </form>
    </section>
  </div>
</template>
