
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqMt6TDs_453yOWVZgq4o3BUV_hOzfaJXNOnT0C2iFnDepgMeta } from "/builds/trevo/web/pages/faq.vue?macro=true";
import { default as indexdPpjau9w1zl8iiwH_45Ij2O0HHzvbb3mxPxDqG_q1jxI0Meta } from "/builds/trevo/web/pages/index.vue?macro=true";
import { default as widgetuQ5v6O1LcHOBAJLd0aMezwfR89QUwhfez9ptQr2nl7EMeta } from "/builds/trevo/web/pages/widget.vue?macro=true";
import { default as quem_45somosT_R3j4r3pxw59W_45GHwolafcovNoA0RBqrMYkZ5Au6rsMeta } from "/builds/trevo/web/pages/quem-somos.vue?macro=true";
import { default as agendamentohkSvQr_8DnvzxHg9wHXax94TbXTrBRobf0oLLBPBdpUMeta } from "/builds/trevo/web/pages/agendamento.vue?macro=true";
import { default as indexZILhkbtk2E5B_45JyttfmuyF85f2lbwM7hqdJzT0cgRlgMeta } from "/builds/trevo/web/pages/perfil/index.vue?macro=true";
import { default as index7WI55fwYsYm0V7173biCf9YDaS_45qT_L6jML5mf28KqkMeta } from "/builds/trevo/web/pages/checkout/index.vue?macro=true";
import { default as indexS_45HqE7sa_voaCYAOqaJgAIG7GnSnhWio1C1isCYFCWkMeta } from "/builds/trevo/web/pages/[location]/index.vue?macro=true";
import { default as qr_45codeHrqp_45lh0Bkvbq_KG_45npKbjQiVD0Jyi6tFedrAyNMDAoMeta } from "/builds/trevo/web/pages/checkout/qr-code.vue?macro=true";
import { default as compra_45garantidaof1Gm_dz2TeNoN7GoRU2ht6sAM2esmHI74_45pGVixjlMMeta } from "/builds/trevo/web/pages/compra-garantida.vue?macro=true";
import { default as pagamentoVLprqAgLlZHw5P7D1ZgYnZz6ZjsHEgxqYa8Pd9nIMrUMeta } from "/builds/trevo/web/pages/checkout/pagamento.vue?macro=true";
import { default as meus_45cupons3DLKU3lgf_DD1Jg_45NyBH6FP623V2VCA0OGZ7Mhnn8fwMeta } from "/builds/trevo/web/pages/perfil/meus-cupons.vue?macro=true";
import { default as termos_45e_45condicoesFB3Eud4layqTPrTYNR1rjcwOO0Dh7XiE8HZ67Mns1nEMeta } from "/builds/trevo/web/pages/termos-e-condicoes.vue?macro=true";
import { default as pagamento_45protegidosdqLQbKQggsU93OsULrynTJ5ktjFqdmUDSFzV7XQEvsMeta } from "/builds/trevo/web/pages/pagamento-protegido.vue?macro=true";
import { default as pedido_45medicoe_y8x9CIi_45Rg6CTXUHEOLavc7i6XLGtVlna2wWaQB_45gMeta } from "/builds/trevo/web/pages/checkout/pedido-medico.vue?macro=true";
import { default as index1Jv75gZmL9F8xH0irnnQWbfUGM4dGv45cyvvjZl284kMeta } from "/builds/trevo/web/pages/[location]/exames/index.vue?macro=true";
import { default as dados_45pessoaisColxyNDqDK_ie2aslcHGG4dPbqRMcb04lfmtOoL8WyIMeta } from "/builds/trevo/web/pages/checkout/dados-pessoais.vue?macro=true";
import { default as dados_45cadastraisEz_rNb94ov1hlvrpdnPJ2K1XJ6zp_3wWsfQmPFR8aI0Meta } from "/builds/trevo/web/pages/perfil/dados-cadastrais.vue?macro=true";
import { default as buscar_45examestpvSbY9H4TnK9vl5F2Iy_45J6SUdRlgSwgQb6m08N4zbEMeta } from "/builds/trevo/web/pages/[location]/buscar-exames.vue?macro=true";
import { default as _91slug_93MoHxBwpj5IDaWWh9q96KaHc4q_45wNgeQzK6pWbYg1M2AMeta } from "/builds/trevo/web/pages/[location]/exames/[slug].vue?macro=true";
import { default as politicas_45de_45privacidadepOMZSY9_peIniZDlQ1GMP7xo_453Zmcps1HDS_qcrPjLIMeta } from "/builds/trevo/web/pages/politicas-de-privacidade.vue?macro=true";
import { default as solicitacao_45de_45orcamentorGSG_45qZqFfp6lsjeZKibMpPeMHZJ1D_45cJxYSp7O1y7sMeta } from "/builds/trevo/web/pages/solicitacao-de-orcamento.vue?macro=true";
import { default as pedido_45realizadouDNrbScAxSa4ktwl1Iw3RcgRgbyBu7Fp0M8gmpPFgxsMeta } from "/builds/trevo/web/pages/checkout/pedido-realizado.vue?macro=true";
import { default as revisao_45de_45dadosZO1uB670lLEtUts6Nr6nJr3_45lNBf9IUgO7rZ27W9CswMeta } from "/builds/trevo/web/pages/checkout/revisao-de-dados.vue?macro=true";
import { default as indexTVPpGiIugcPlZTyRzRY7g6nFwVSAwyu1t6uiSoJf370Meta } from "/builds/trevo/web/pages/perfil/meus-pedidos/index.vue?macro=true";
import { default as forma_45de_45pagamentoXdEvVVOa7KtWNZTpavWr2A0CM_45mh_45FyYAl1Nm7LxfQkMeta } from "/builds/trevo/web/pages/checkout/forma-de-pagamento.vue?macro=true";
import { default as pagamento_45aprovadoB1b0NkCwOx6oCcFCzo71UUhEBAatZt7JhSVffMdLV40Meta } from "/builds/trevo/web/pages/checkout/pagamento-aprovado.vue?macro=true";
import { default as endereco_45de_45cobrancaATjtm5KPhUt83xjgjV5JuOVdnstUjU8Cf_zWZQpzfQQMeta } from "/builds/trevo/web/pages/checkout/endereco-de-cobranca.vue?macro=true";
import { default as processando_45pagamentomxwhkOZSinKr6isOqi3AuGYCadu_qdiFh7_fyE8_xIEMeta } from "/builds/trevo/web/pages/checkout/processando-pagamento.vue?macro=true";
import { default as _91category_93bmsstD9joClzbVpnv6edCffcGE7VG8_hT36_PgrQ6dwMeta } from "/builds/trevo/web/pages/[location]/categorias/[category].vue?macro=true";
import { default as dados_45do_45plano_45de_45saude9RG2By0IJ564quSM1liDE7H4Tc8cQcddGaq4oUZu8dIMeta } from "/builds/trevo/web/pages/checkout/dados-do-plano-de-saude.vue?macro=true";
import { default as _91orderNumber_93WREDPNmwNEQHAAkSAqs8aWKPNRRxSdPp__NqGeE4X44Meta } from "/builds/trevo/web/pages/perfil/meus-pedidos/[orderNumber].vue?macro=true";
import { default as confirmacao_45de_45agendamentotgoDBf799ElIa93nimkh_eaw0gc4I8UQjZEzs1LGs00Meta } from "/builds/trevo/web/pages/checkout/confirmacao-de-agendamento.vue?macro=true";
export default [
  {
    name: "faq",
    path: "/faq",
    component: () => import("/builds/trevo/web/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/trevo/web/pages/index.vue")
  },
  {
    name: "widget",
    path: "/widget",
    meta: widgetuQ5v6O1LcHOBAJLd0aMezwfR89QUwhfez9ptQr2nl7EMeta || {},
    component: () => import("/builds/trevo/web/pages/widget.vue")
  },
  {
    name: "quem-somos",
    path: "/quem-somos",
    meta: quem_45somosT_R3j4r3pxw59W_45GHwolafcovNoA0RBqrMYkZ5Au6rsMeta || {},
    component: () => import("/builds/trevo/web/pages/quem-somos.vue")
  },
  {
    name: "agendamento",
    path: "/agendamento",
    meta: agendamentohkSvQr_8DnvzxHg9wHXax94TbXTrBRobf0oLLBPBdpUMeta || {},
    component: () => import("/builds/trevo/web/pages/agendamento.vue")
  },
  {
    name: "perfil",
    path: "/perfil",
    meta: indexZILhkbtk2E5B_45JyttfmuyF85f2lbwM7hqdJzT0cgRlgMeta || {},
    component: () => import("/builds/trevo/web/pages/perfil/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: index7WI55fwYsYm0V7173biCf9YDaS_45qT_L6jML5mf28KqkMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/index.vue")
  },
  {
    name: "location",
    path: "/:location()",
    component: () => import("/builds/trevo/web/pages/[location]/index.vue")
  },
  {
    name: "checkout-qr-code",
    path: "/checkout/qr-code",
    meta: qr_45codeHrqp_45lh0Bkvbq_KG_45npKbjQiVD0Jyi6tFedrAyNMDAoMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/qr-code.vue")
  },
  {
    name: "compra-garantida",
    path: "/compra-garantida",
    meta: compra_45garantidaof1Gm_dz2TeNoN7GoRU2ht6sAM2esmHI74_45pGVixjlMMeta || {},
    component: () => import("/builds/trevo/web/pages/compra-garantida.vue")
  },
  {
    name: "checkout-pagamento",
    path: "/checkout/pagamento",
    meta: pagamentoVLprqAgLlZHw5P7D1ZgYnZz6ZjsHEgxqYa8Pd9nIMrUMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pagamento.vue")
  },
  {
    name: "perfil-meus-cupons",
    path: "/perfil/meus-cupons",
    meta: meus_45cupons3DLKU3lgf_DD1Jg_45NyBH6FP623V2VCA0OGZ7Mhnn8fwMeta || {},
    component: () => import("/builds/trevo/web/pages/perfil/meus-cupons.vue")
  },
  {
    name: "termos-e-condicoes",
    path: "/termos-e-condicoes",
    component: () => import("/builds/trevo/web/pages/termos-e-condicoes.vue")
  },
  {
    name: "pagamento-protegido",
    path: "/pagamento-protegido",
    component: () => import("/builds/trevo/web/pages/pagamento-protegido.vue")
  },
  {
    name: "checkout-pedido-medico",
    path: "/checkout/pedido-medico",
    component: () => import("/builds/trevo/web/pages/checkout/pedido-medico.vue")
  },
  {
    name: "location-exames",
    path: "/:location()/exames",
    component: () => import("/builds/trevo/web/pages/[location]/exames/index.vue")
  },
  {
    name: "checkout-dados-pessoais",
    path: "/checkout/dados-pessoais",
    component: () => import("/builds/trevo/web/pages/checkout/dados-pessoais.vue")
  },
  {
    name: "perfil-dados-cadastrais",
    path: "/perfil/dados-cadastrais",
    meta: dados_45cadastraisEz_rNb94ov1hlvrpdnPJ2K1XJ6zp_3wWsfQmPFR8aI0Meta || {},
    component: () => import("/builds/trevo/web/pages/perfil/dados-cadastrais.vue")
  },
  {
    name: "location-buscar-exames",
    path: "/:location()/buscar-exames",
    component: () => import("/builds/trevo/web/pages/[location]/buscar-exames.vue")
  },
  {
    name: "location-exames-slug",
    path: "/:location()/exames/:slug()",
    component: () => import("/builds/trevo/web/pages/[location]/exames/[slug].vue")
  },
  {
    name: "politicas-de-privacidade",
    path: "/politicas-de-privacidade",
    meta: politicas_45de_45privacidadepOMZSY9_peIniZDlQ1GMP7xo_453Zmcps1HDS_qcrPjLIMeta || {},
    component: () => import("/builds/trevo/web/pages/politicas-de-privacidade.vue")
  },
  {
    name: "solicitacao-de-orcamento",
    path: "/solicitacao-de-orcamento",
    component: () => import("/builds/trevo/web/pages/solicitacao-de-orcamento.vue")
  },
  {
    name: "checkout-pedido-realizado",
    path: "/checkout/pedido-realizado",
    meta: pedido_45realizadouDNrbScAxSa4ktwl1Iw3RcgRgbyBu7Fp0M8gmpPFgxsMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pedido-realizado.vue")
  },
  {
    name: "checkout-revisao-de-dados",
    path: "/checkout/revisao-de-dados",
    meta: revisao_45de_45dadosZO1uB670lLEtUts6Nr6nJr3_45lNBf9IUgO7rZ27W9CswMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/revisao-de-dados.vue")
  },
  {
    name: "perfil-meus-pedidos",
    path: "/perfil/meus-pedidos",
    meta: indexTVPpGiIugcPlZTyRzRY7g6nFwVSAwyu1t6uiSoJf370Meta || {},
    component: () => import("/builds/trevo/web/pages/perfil/meus-pedidos/index.vue")
  },
  {
    name: "checkout-forma-de-pagamento",
    path: "/checkout/forma-de-pagamento",
    meta: forma_45de_45pagamentoXdEvVVOa7KtWNZTpavWr2A0CM_45mh_45FyYAl1Nm7LxfQkMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/forma-de-pagamento.vue")
  },
  {
    name: "checkout-pagamento-aprovado",
    path: "/checkout/pagamento-aprovado",
    meta: pagamento_45aprovadoB1b0NkCwOx6oCcFCzo71UUhEBAatZt7JhSVffMdLV40Meta || {},
    component: () => import("/builds/trevo/web/pages/checkout/pagamento-aprovado.vue")
  },
  {
    name: "checkout-endereco-de-cobranca",
    path: "/checkout/endereco-de-cobranca",
    component: () => import("/builds/trevo/web/pages/checkout/endereco-de-cobranca.vue")
  },
  {
    name: "checkout-processando-pagamento",
    path: "/checkout/processando-pagamento",
    meta: processando_45pagamentomxwhkOZSinKr6isOqi3AuGYCadu_qdiFh7_fyE8_xIEMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/processando-pagamento.vue")
  },
  {
    name: "location-categorias-category",
    path: "/:location()/categorias/:category()",
    component: () => import("/builds/trevo/web/pages/[location]/categorias/[category].vue")
  },
  {
    name: "checkout-dados-do-plano-de-saude",
    path: "/checkout/dados-do-plano-de-saude",
    meta: dados_45do_45plano_45de_45saude9RG2By0IJ564quSM1liDE7H4Tc8cQcddGaq4oUZu8dIMeta || {},
    component: () => import("/builds/trevo/web/pages/checkout/dados-do-plano-de-saude.vue")
  },
  {
    name: "perfil-meus-pedidos-orderNumber",
    path: "/perfil/meus-pedidos/:orderNumber()",
    meta: _91orderNumber_93WREDPNmwNEQHAAkSAqs8aWKPNRRxSdPp__NqGeE4X44Meta || {},
    component: () => import("/builds/trevo/web/pages/perfil/meus-pedidos/[orderNumber].vue")
  },
  {
    name: "checkout-confirmacao-de-agendamento",
    path: "/checkout/confirmacao-de-agendamento",
    meta: confirmacao_45de_45agendamentotgoDBf799ElIa93nimkh_eaw0gc4I8UQjZEzs1LGs00Meta || {},
    component: () => import("/builds/trevo/web/pages/checkout/confirmacao-de-agendamento.vue")
  }
]