import type { IPromo } from '@/types/types'
import { LocalStorageKey } from '@/types/enums'

export default () => useAsyncData(
  'promos',
  async () => {
    const customerId = useCustomer().customer.value.id
    if (!customerId)
      return [] as IPromo[]

    return await $fetch<IPromo[]>(`/v1/customer/users/${customerId}/promos`, {
      baseURL: useRuntimeConfig().public.api.awsGateway,
      headers: {
        AuthToken: useLocalStorage(LocalStorageKey.AccessToken, '').value,
      },
    })
  },
  { watch: [() => useCustomer().customer.value.id] },
)
