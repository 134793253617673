<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },

  type: {
    type: String,
    default: 'text',
  },

  label: {
    type: String,
    required: true,
  },

  mask: {
    type: String,
    default: '',
  },

  modelValue: {
    type: String,
    default: '',
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  max: {
    type: Number,
    default: 100,
  },

  min: {
    type: Number,
    default: 0,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  customErrorMessage: {
    type: String,
    default: '',
  },

  dataTestid: {
    type: String,
    default: '',
  },

  validation: {
    type: Object,
    default: () => ({ $errors: [] }),
  },
})

const emit = defineEmits(['update:modelValue', 'change', 'enter'])
const hasValue = ref(props.modelValue && props.modelValue.trim() !== '')

const hasError = computed(() => {
  return props.validation.$errors?.length > 0
})
const errorMessage = computed(() => {
  return props.customErrorMessage
    ? props.customErrorMessage
    : props.validation.$errors[0]?.$message
})

const inputClasses = computed(() => ({
  't-input-text--disabled': props.disabled,
  't-input-text--error': hasError.value,
  't-input-text--value': hasValue.value,
  't-input-text--without-value': !hasValue.value,
}))

const spanClasses = computed(() => ({
  't-input-text__span': true,
  't-input-text__span--value': hasValue.value,
  't-input-text__span--without-value': !hasValue.value,
  't-input-text__span--error': hasError.value,
}))

function handleChange(e: any) {
  hasValue.value = e.target.value.trim() !== ''

  emit('update:modelValue', e.target.value.trim())
  emit('change')
}

watch(
  () => props.modelValue,
  () => {
    hasValue.value = props.modelValue && props.modelValue.trim() !== ''
  },
  { immediate: true },
)
</script>

<template>
  <div class="flex flex-col w-full relative">
    <div
      class="h-14 p-0 rounded-lg peer focus:outline-none bg-transparent relative"
    >
      <label :for="props.id" class="sr-only"> {{ props.label }}: </label>

      <input
        :id="props.id"
        v-maska
        :maxlength="max"
        :min="min"
        :value="props.modelValue"
        :data-testid="props.dataTestid"
        :class="inputClasses"
        class="peer t-input-text"
        :data-maska="props.mask"
        :type="props.type"
        :disabled="props.disabled"
        @input="handleChange"
        @keydown.enter.prevent="$emit('enter')"
      />

      <span
        class="t-input-text__span before:content[' '] after:content[' ']"
        :class="spanClasses"
      >
        {{ props.label }}
      </span>

      <Icon
        v-if="props.loading"
        name="mdi:loading"
        class="animate-spin text-primary-400 absolute right-[16px] top-0 bottom-0 my-auto"
        height="24"
        width="24"
      />
    </div>

    <div class="flex items-center h-6 mt-2">
      <Icon
        name="mdi:information-outline"
        :class="
          hasError ? 'text-error-500' : 'text-transparent'
        "
        class="w-4 h-4 mr-2"
      />
      <small
        :class="
          hasError ? 'text-error-500' : 'text-transparent'
        "
        class="mt-[3px]"
      >
        {{ errorMessage }}
      </small>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.t-input-text {
  @apply border-neutral-50 focus:border-primary-400 h-full w-full rounded-lg border bg-transparent duration-200 p-3 text-sm font-bold text-neutral-400 outline outline-0 transition-all placeholder-shown:border focus:border focus:border-t-transparent focus:outline-0;
}

.t-input-text--without-value {
  @apply hover:border-t-transparent;
}

.t-input-text--value {
  @apply border-t-transparent placeholder-shown:border-t-neutral-50 h-full w-full rounded-lg;
}

.t-input-text--disabled {
  @apply text-neutral-200 bg-base-50;
}

.t-input-text--error {
  @apply border-t-transparent border-error-500 text-neutral-400 focus:border-error-500 focus:border-t-transparent;
}

.t-input-text__span {
  @apply peer-focus:after:border-primary-400 peer-focus:text-primary-400 peer-focus:before:border-primary-400  peer-placeholder-shown:text-neutral-200 pointer-events-none absolute left-0 -top-1.5 flex h-full text-neutral-50 w-full select-none text-sm font-normal transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-transparent before:focus:border-neutral-50 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-transparent after:focus:border-neutral-50 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-hover:text-xs peer-hover:mt-0 peer-hover:before:border-t peer-hover:before:border-l peer-focus:text-xs peer-focus:mt-0 peer-focus:before:border-t peer-focus:before:border-l peer-hover:border-neutral-50 peer-hover:after:border-neutral-50 peer-hover:before:border-neutral-50 peer-focus:after:border-t peer-focus:after:border-r peer-hover:after:border-t peer-hover:after:border-r duration-100 before:duration-100 after:duration-100;
}

.t-input-text__span--value {
  @apply mt-0 after:border-t after:border-r text-xs before:border-t before:border-l before:border-t-neutral-50 after:border-t-neutral-50;
}

.t-input-text__span--without-value {
  @apply mt-6 after:border-t after:border-r text-xs before:border-t before:border-l before:border-t-transparent after:border-t-transparent;
}

.t-input-text__span--error {
  @apply mt-0 text-xs peer-focus:text-error-500 peer-focus:after:border-error-500 after:border-error-500 before:border-error-500 peer-focus:before:border-error-500 peer-hover:before:border-error-500 peer-hover:after:border-error-500 text-error-500 peer-placeholder-shown:text-error-500;
}
</style>
