<script lang="ts" setup>
type TButtonType = 'button' | 'submit' | 'reset'

type IButtonVariant =
  | 'default'
  | 'success'
  | 'outlined'
  | 'outlinedWhite'
  | 'outlinedSecondary'
  | 'text'
  | 'yellow'
  | 'error'
  | 'white'
  | 'disabled'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<IButtonVariant>,
    default: 'default',
  },
  full: {
    type: Boolean,
    default: false,
  },
  buttonType: {
    type: String as PropType<TButtonType>,
    default: 'button',
  },
})

const variantClass = computed(() =>
  props.disabled ? 'disabled' : props.variant,
)
</script>

<template>
  <button
    :class="`button ${variantClass} ${full ? 'full' : ''} ${
      loading ? 'loading' : ''
    }`"
    class="flex items-center relative justify-center"
    :disabled="disabled || loading"
    :type="buttonType"
  >
    <Icon
      v-if="loading"
      name="mdi:loading"
      class="w-5 h-5 animate-spin absolute text-base-50 self-center"
    />

    <span :class="{ invisible: loading }" class="flex items-center">
      <Icon v-if="icon" :name="icon" class="w-4 h-4 mr-2" />
      <slot />
    </span>
  </button>
</template>

<style lang="postcss">
.button {
  @apply flex items-center justify-center p-3 sm:px-6 rounded-lg text-xs font-semibold border-2;

  &.default {
    @apply bg-secondary-300 text-bg-50 border-transparent duration-300 hover:bg-secondary-400;
  }

  &.white {
    @apply bg-white text-primary-400 border-white;
  }

  &.error {
    @apply text-error-500 bg-error-50;
  }

  &.yellow {
    @apply bg-tertiary-400 text-bg-50 border-transparent hover:bg-tertiary-500 duration-300;
  }

  &.success {
    @apply bg-success-200 border-transparent hover:bg-success-200 duration-300 text-bg-50;
  }

  &.outlined {
    @apply border-2 border-secondary-300 text-secondary-300 bg-transparent;
  }

  &.outlinedWhite {
    @apply border border-white text-bg-50 bg-transparent;
  }

  &.outlinedSecondary {
    @apply border border-secondary-400 hover:border-secondary-300 hover:text-secondary-300 bg-transparent transition-all;
  }

  &.text {
    @apply border-0 text-primary-400 bg-transparent;
  }

  &.textSecondary {
    @apply border-0 text-secondary-300 bg-transparent;
  }

  &.full {
    @apply w-full;
  }

  &.disabled {
    @apply text-base-50 cursor-not-allowed border-transparent bg-neutral-50;
  }

  &.loading {
    @apply bg-secondary-500 border-transparent cursor-wait;
  }
}
</style>
