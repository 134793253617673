import { AuthSendTokenErrorMessage } from '@/types/enums'
import useAuth from './useAuth'

interface ISendTokenPayload {
  phone: string
  userIdentifiers: {
    userPseudoId: string | undefined
    event: string
    gclid: string | null
    gbraid: string | null
    wbraid: string | null
    abGroup: string
    deviceId: string | undefined
    sessionId: string | undefined
  }
}

export default async (payload: ISendTokenPayload) => {
  const { loading, errors } = useAuth()

  try {
    loading.value = true

    const baseURL = useRuntimeConfig().public.api.awsGateway
    const path = `/v2/customer/users/request_phone_code`

    await $fetch(path, { baseURL, method: 'POST', body: JSON.stringify(camelToSnake(payload)) })
    return true
  }
  catch (error: any) {
    handleApiErrors(
      error,
      errors,
      AuthSendTokenErrorMessage,
      'Falha ao enviar código de verificação SMS.',
    )
    return false
  }
  finally {
    loading.value = false
  }
}
