export default defineNuxtRouteMiddleware((to) => {
  const { path, query } = to

  if (path.startsWith('/exames/') && query.exames) {
    const location = path.split('/')[2]
    const slug = query.exames

    return navigateTo(`/${location}/exames/${slug}`)
  }

  const oldRoutePattern = /^\/exames\/([a-zA-Z-]+)\/([a-zA-Z-]+)$/
  const match = path.match(oldRoutePattern)

  if (match) {
    const location = match[1]
    const slug = match[2]

    return navigateTo(`/${location}/exames/${slug}`)
  }
})
