import { definePreset } from '@primeuix/themes'
import Aura from '@primeuix/themes/aura'

import Color from 'color'

const SHADES_730099 = {
  50: '#FCF2FF',
  100: '#F6DBFF',
  200: '#CC33FF',
  300: '#AD00E6',
  400: '#730099',
  500: '#4D0066',
}

const SHADES_234130 = {
  50: '#F2FAF5',
  100: '#CEECDA',
  200: '#99C6AB',
  300: '#4B765D',
  400: '#234130',
  500: '#0F2D1C',
}

const SHADES_00C1AB = {
  50: '#E2FFFA',
  100: '#C3F4EC',
  200: '#7DC1B6',
  300: '#54C6B9',
  400: '#00C1AB',
  500: '#055048',
}

const DEFAULT_LEVELS = [50, 100, 200, 300, 400, 500]

function generateColorShades(baseColor) {
  const shades = {}
  switch (baseColor) {
    case '#730099':
      return SHADES_730099
    case '#234130':
      return SHADES_234130
    case '#00C1AB':
      return SHADES_00C1AB
    default:
      DEFAULT_LEVELS.forEach((i) => {
        const ratio = Math.abs(400 - i) / 400
        const mixColor = i < 400 ? '#ffffff' : '#000000'
        shades[i] = Color(baseColor).mix(Color(mixColor), ratio).hex()
      })
      return shades
  }
}

const BUILD_CONTENT = process.env.VITE_BUILD_CONTENT ? JSON.parse(process.env.VITE_BUILD_CONTENT) : {}

function colors() {
  return {
    primary: generateColorShades(BUILD_CONTENT.primaryColor),
    secondary: generateColorShades(BUILD_CONTENT.secondaryColor),
    tertiary: generateColorShades(BUILD_CONTENT.tertiaryColor),
  }
}

const trevoTheme = definePreset(Aura, {
  semantic: {
    ...colors(),
  },
})

export default {
  preset: trevoTheme,
  options: {
    locale: {
      accept: 'Portuguese',
    },
    darkModeSelector: false,
  },
}
