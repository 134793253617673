import { LocalStorageKey, SessionStorageKey } from '@/types/enums'

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    const orderCreated = useSessionStorage(SessionStorageKey.OrderCreated, '')

    if (!orderCreated.value)
      return

    const routesToKeep = [
      'checkout-processando-pagamento',
      'checkout-qr-code',
      'checkout-revisao-de-dados',
      'checkout-pedido-realizado',
      'checkout-pagamento-aprovado',
    ]

    if (routesToKeep.includes(to.name as string))
      return

    nextTick(() => {
      useLocalStorage(LocalStorageKey.Cart, null).value = null
      useSessionStorage(SessionStorageKey.OrderCreated, null).value = null
      useSessionStorage(SessionStorageKey.BundleSelected, null).value = null
      useSessionStorage(SessionStorageKey.SelectedHealthPlan, null).value = null

      useJumpPersonalData().value = true
    })
  }
})
