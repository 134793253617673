/**
 * Formats or removes formatting of a Brazilian CPF (Tax ID) number.
 *
 * @param {string} taxId - The string containing the CPF number, which may or may not be formatted with dots and dashes.
 * @param {object} options - Options for formatting.
 * @param {boolean} options.mask - If true, formats the CPF; if false, removes formatting.
 * @returns {string} - Returns the formatted or cleaned CPF based on the options provided.
 */
export default function formatTaxId(taxId: string, options: { mask: boolean }): string {
  if (typeof options.mask !== 'boolean')
    return taxId

  const cleanTaxId = taxId.replace(/\D/g, '')

  if (options.mask) {
    if (/^\d{11}$/.test(cleanTaxId)) {
      return cleanTaxId.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }
    else {
      return taxId
    }
  }
  else {
    return cleanTaxId
  }
}
