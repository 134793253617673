<script lang="ts" setup>
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  notSidesheet: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['close', 'show'])

watch(() => props.show, (show) => {
  if (show)
    emit('show')
}, {
  immediate: true,
})
</script>

<template>
  <div>
    <teleport to="body">
      <div
        v-if="props.show"
        class="transition-all duration-500 ease-in-out fixed inset-0 flex items-center justify-center bg-opacity-80 bg-neutral-500 z-40"
        @click="$emit('close')"
      />
    </teleport>

    <teleport to="body">
      <div
        v-if="props.show"
        class="transition-all duration-500 ease-in-out fixed flex self-center justify-center bg-white rounded-lg z-50 max-h-lvh overflow-y-auto w-fit"
        :class="[
          {
            'xl:left-1/2 xl:-translate-x-1/2 bottom-0 w-full xl:w-fit xl:inset-0': !notSidesheet,
            'w-[calc(100vw_-_32px)] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2': notSidesheet,
          },
        ]"
      >
        <slot />
      </div>
    </teleport>
  </div>
</template>
